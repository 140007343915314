.mobile-topbar {
    width:100%;
    background: #161616 ;
    display:inline-block;
    height: 50px;
}

.mobile-topbar-left {
    float: left;
}

.mobile-topbar-right {
    float: right;
    margin-right: 25px;
    margin-top: 13px;
}

