.loadingIcon{
    margin-left: 40%;
}
.treeView {
    height:200px;
}

.MuiTypography-body1 {
    font-family: Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif !important;
    font-weight: 520 !important;
    font-size: 1rem !important;
    color: #959595;
}

.MuiOutlinedInput-root {
    border-radius: 0px !important;
    background-color: #2A2A2A;
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    color:#EDEDED
}

.MuiTreeItem-label {
    display: inline-block;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    width: 200px; 
}

*::-webkit-scrollbar {
    width: 0.4em;
}
*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}
*::-webkit-scrollbar-thumb {
    background-color: #5f6a77;
    outline: 1px solid #5f6a77;
}

