#dossierContainer,
#dossierFrame {
  height: 100% !important;
  position: relative;
}

.MuiMobileStepper-dotActive {
  background-color: #5f6a77 !important;
}

.MuiMobileStepper-root {
  overflow: hidden;
}

.transparentCover {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.alert{
  font-size: 12px;
  padding: 14px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.closeIcon{
  color:rgb(30,70,32);
  font-weight: bold;
  cursor: pointer;
}

.alert-text{
  margin-right: 24px;
  color: black;
}

.DashboardAlertImage {
  position: absolute;
  bottom: 5px;
  cursor: pointer;
  right: 0;
  margin: 20px;
  height: 42px;
}

.DashboardAlert {
  position: absolute;
  bottom: 0px;
  right: 52px;
  margin: 20px;
  display: flex;
  background-color: #edf7ed;
  border: 1px solid #4caf50;
  box-shadow: 0 1px 2px 0 rgb(#4CAF50/ 30%), 0 1px 3px 1px rgb(#4caf50 / 15%);
  align-items: center;
  /* padding: 12px 20px 6px 12px; */
  border-radius: 16px;

  width: fit-content;
  max-width: 40vw;
  max-height: 50vh;
  height: -moz-fit-content;
  height: fit-content;
  animation-name: slide-right;
  animation-duration: 0.4s;
  animation-fill-mode: both;
}

.closeIconNotification {
  color: rgb(59, 94, 61);
  font-weight: bold;
  top: -10px;
  cursor: pointer;
  height: 26px;
  position: absolute;
  cursor: pointer;
  right: -8px;
}

.closeIconNotification:hover {
  color: rgb(3, 40, 5);
}

.alert-text {
  color: black;
}

.alertTextLayout {
  padding-right: 10px;
  padding-top: 10px;
  max-width: 65vw;
  overflow-y: scroll;
  max-height: 26vh;
  margin: 0 0 10px 10px;

}

@keyframes slide-right {
  from {
    opacity: 0;
  }

  25% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.6;
  }

  75% {
    opacity: 0.8;
  }

  to {
    opacity: 1;
  }
}

/* media queries */

@media only screen and (max-width: 600px) {
  .DashboardAlert {
    bottom: 55px;
    right: 5px;
    width: fit-content;
    max-height: 40vh;
    max-width: 66vw;
  
  }
  .alert-text {
    color: black;
  }

  .alertTextLayout {
    max-height: 40vh;
    max-width: 66vw;
    width: fit-content;
    margin-top: 10px;
    height: fit-content;
  }
}



.loader-line {
  width: 100%;
  height: 3px;
  position: relative;
  overflow: hidden;
  display:flex;
  justify-content:center;
  background-color: #a1c9ff;
 
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #4b8add;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
      left: -40%;
  }
  50% {
      left: 20%;
      width: 80%;
  }
  100% {
      left: 100%;
      width: 100%;
  }
}