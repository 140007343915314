.LeftBarBtnGroup-container {
    margin-top: 7px;
    margin-left: 7px;
}

.LeftBarBtnGroup-btnGroup {
    border-radius: 0px !important;
}

.LeftBarBtnGroup-btn-notSelected {
    min-width: 20px !important;
    border-radius: 0px !important;
    border: 1px solid #5F6A77 !important;
    background: #2A2A2A !important;
}

.LeftBarBtnGroup-btn-notSelected span {
    color: #5F6A77;
}

.LeftBarBtnGroup-btn-selected {
    min-width: 20px !important;
    border-radius: 0px !important;
    border: 1px solid #5F6A77 !important;
    background: #5F6A77 !important;
}

.LeftBarBtnGroup-btn-selected span {
    color: #2A2A2A;
}

.LeftBarBtnGroup-favorite {
    min-width: 20px !important;
    border-radius: 0px !important;
    border: 0px !important;
    border-left: 1px solid #5F6A77 !important;
    background: #161616 !important;
}

.LeftBarBtnGroup-favorite span {
    color: gold;
}

