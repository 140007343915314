.tree {
    margin:16px !important;
    padding-bottom: 15px !important;
}

.treeNode {
    margin-top:10px !important;
}

.MuiListItemText-root {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.menuItems {
    height:100%;
}

.menu-item-icon {
   padding-left: 22px !important;
}

.MuiDrawer-paper {
    font-family: Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif !important; 
    color:  #5F6A77 !important;
    background-color: #161616 !important;
}

.MuiDivider-root {
    background-color:  #5F6A77 !important;
}

/* Rubik-499 RUBIK header text- Update the font to Times New Roman*/
.appTitle {
    color: #ededed;
    font-size: 1.8rem !important;
    margin-right: 25%;
    margin-left: 5%;
    font-family: "Times New Roman", Times, serif;
}

.MuiPaper-root {
    overflow-x: hidden;
}

#mainDashboardContainer {
    padding: 0 !important;
}

@media only screen and (max-width: 600px) {
    #sideMenu {
      display: none !important;
    }

    #topbar {
        display: block !important;
    }
    
    #menuContainer {
        display: inline;
    }

    #mainDashboard {
        height: 92% !important;
    }
}

@media only screen and (min-width: 601px) {
    #sideMenu {
      display: block !important;
    }

    #topbar {
        display: none !important;
    }

    /**
        Clear up menuContainer or normal height. Dummy value is added to remove warning.
    */
    #menuContainer {
        margin-top: 0px;
    }
}
