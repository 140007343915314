.image {
    width: 300px;
    height: 300px;
    border-radius: 10px;
}

.image-card {
    padding: 5px;
}

.image-gallary-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


/* Image Form */
.drop-zone {
    width: 200px;
    height: 200px;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-prompt {
    text-align: center;
}

.image-preview {
    /* border: 1px solid green; */
    display: flex;
    width: 30%;
    margin: 8px;
    border-radius: 12px;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    align-items: center;
}

.image-preview img {
    width: 68%;
    border-radius: 12px;
    margin-top: 20px;
    object-fit: contain;
}

.image-preview button {
    text-align: center;
    font-size: 16px;
    border-radius: 12px;
    padding: 6px;
    border: none;
    font-weight: 600;
    background-color: #6a64f1;
    color: white;
    cursor: pointer;
    width: 75%;
    margin: 0 padding;
    margin-bottom: 20px;
}

.image-preview-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.image-preview-name {
    font-size: 12px;
    margin: 20px 10px;
}


/* form */

.formbold-main-wrapper {
    /* display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px; */
}

.formbold-form-wrapper {
    margin: 0 auto;
    max-width: 550px;
    width: 100%;
    background: white;
}

.formbold-mb-5 {
    margin-bottom: 20px;
}

.formbold-form-label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #07074d;
    margin-bottom: 12px;
}


.formbold-form-input {
    width: 100%;
    padding: 12px 24px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    background: white;
    font-weight: 500;
    box-sizing: border-box;
    font-size: 16px;
    color: #6b7280;
    outline: none;
    resize: none;
}

.formbold-form-input:focus {
    border-color: #6a64f1;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.image-drop-zone {
    border: 1px dashed #e0e0e0;
    display: flex;
    height: 30vh;
    border-radius: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
}

.formbold-form-wrapper {
    margin: 30px auto;
    max-width: 550px;
    width: 100%;
    background: white;
}


.formbold-form-textarea {
    width: 100%;
    padding: 12px 24px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    background: white;
    font-weight: 500;
    font-size: 16px;
    color: #6b7280;
    font-family: inherit;
    height: 16vh;
    box-sizing: border-box;
    outline: none;
    font-size: inherit;

}

.formbold-form-textarea:focus {
    border-color: #6a64f1;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.image-drop-zone-layout {}

.formbold-btn {
    text-align: center;
    font-size: 16px;
    border-radius: 6px;
    padding: 14px 32px;
    border: none;
    font-weight: 600;
    background-color: #6a64f1;
    color: white;
    cursor: pointer;
}

.formbold-btn:hover {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.w-full {
    width: 100%;
}

