.sidebar-title{
    color: #ededed;
    font-size:1.1rem;
    font-family: Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif !important; 
    text-align: center;
    margin: auto 50% auto 2%;
}

.sideBarIcon {
    color: #ededed;
    font-size:1.5rem;
}

.sideBarIconDisabled {
    color:  #5F6A77;
}

.MuiIconButton-root {
    padding: 0px !important;
}


