
@media only screen and (max-width: 600px) {
    .logo {  
        padding-top: 3px;
        margin-left: 5px;
        float:left;
        cursor: pointer;
    }
}

@media only screen and (min-width: 601px) {
    .logo {  
        padding-top: 15px;
        padding-bottom: 10px;
        cursor: pointer;
    }
}
