.openbar-drawer {
    height: '100vh';
    overflow-y: "auto";
    position:absolute;
    width: 100%;
}

.openbar-drawer .MuiPaper-root {
    width: 100%;
    height: 100%;
}

.mobile-openbar {
    float: right;
    margin-right: 25px !important;
    margin-top: 13px !important;
}

/* Rubik-499 RUBIK header text- Update the font to Times New Roman*/
.mobile-appTitle {
    float:left;
    text-align:center;
    color: #ededed;
    font-size: 32px !important;
    margin-left:27%;
    padding-top: 2px;
    font-family: "Times New Roman", Times, serif;
}